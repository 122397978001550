// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs";
import Turbolinks from "turbolinks";
import * as ActiveStorage from "@rails/activestorage";
import "channels";
import "@fortawesome/fontawesome-free/css/all";
import "../layouts/application.css";

import flatpickr from "flatpickr";
import { French } from "flatpickr/dist/l10n/fr.js";
import monthSelectPlugin from "flatpickr/dist/plugins/monthSelect/index";

require("jquery");
require("jquery-ui");

Rails.start();
Turbolinks.start();
ActiveStorage.start();

require("@client-side-validations/client-side-validations");
require("@client-side-validations/simple-form");
